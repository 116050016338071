<template>
  <div>
    <b-alert v-if="issues.length" variant="danger" show>
      <h4 class="alert-heading">
        Errors found!
      </h4>
      <div class="alert-body">
        <span
          >We have identified errors in your uploaded Excel file. You can
          proceed with the upload, which will skip models with errors, or you
          have the option to resolve these issues and re-upload the file.</span
        >
      </div>
    </b-alert>
    <b-card title="Accepted Data">
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :items="data"
        :fields="fields"
        striped
        responsive
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="vs-checkbox-con"
            @change="row.toggleDetails"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
              </span>
            </span>
            <span class="vs-label">{{
              row.detailsShowing ? "Hide" : "Show"
            }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card title="Issues">
            <!-- row.item.full_name -->
            <b-row class="mb-1">
              <b-col
                ><p class="mb-0"><strong>Name</strong></p></b-col
              >
              <b-col
                ><p class="mb-0"><strong>Price</strong></p></b-col
              >
              <b-col
                ><p class="mb-0"><strong>Strike price</strong></p></b-col
              >
              <b-col
                ><p class="mb-0"><strong>Warranty?</strong></p></b-col
              >
              <b-col
                ><p class="mb-0"><strong>Warranty Price</strong></p></b-col
              >
              <b-col
                ><p class="mb-0"><strong>Warranty Period</strong></p></b-col
              >
              <b-col
                ><p class="mb-0"><strong>Period Value</strong></p></b-col
              >
              <b-col
                ><p class="mb-0"><strong>Private Issue?</strong></p></b-col
              >
            </b-row>
            <div v-for="issue in row.item.issues" :key="issue.issue">
              <b-row class="mb-1">
                <b-col>
                  <p class="mb-0">{{ issue.issuePublicName }}</p>
                </b-col>
                <b-col>
                  <p class="mb-0">{{ issue.price }}</p>
                </b-col>
                <b-col>
                  <p class="mb-0">{{ issue.strikeThroughPrice }}</p>
                </b-col>
                <b-col>
                  <p class="mb-0">
                    {{ issue.warranty.isAvailable === true ? "Yes" : "No" }}
                  </p>
                </b-col>
                <b-col>
                  <p class="mb-0">{{ issue.warranty.price }}</p>
                </b-col>
                <b-col>
                  <p class="mb-0">{{ issue.warranty.period }}</p>
                </b-col>
                <b-col>
                  <p class="mb-0">{{ issue.warranty.periodValue }}</p>
                </b-col>
                <b-col>
                  <p class="mb-0">
                    {{ issue.isPrivate === true ? "Yes" : "No" }}
                  </p>
                </b-col>
              </b-row>
            </div>

            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Hide Details
            </b-button>
          </b-card>
        </template>

        <template #cell(brand)="data">
          <span class="text-nowrap">
            {{ data.item.brandPublicName }}
          </span>
        </template>

        <template #cell(model)="data">
          <span class="text-nowrap">
            {{ data.item.modelPublicName }}
          </span>
        </template>

        <template #cell(inventory)="data">
          <span class="text-nowrap">
            {{ data.item.name }}
          </span>
        </template>

        <template #cell(item_condition)="data">
          <span class="text-nowrap">
            {{ data.item.itemCondition }}
          </span>
        </template>

        <template #cell(stock_warning_at)="data">
          <span class="text-nowrap">
            {{ data.item.stockWarningAt }}
          </span>
        </template>

        <template #cell(unit_price)="data">
          <span class="text-nowrap">
            {{ data.item.unitPrice }}
          </span>
        </template>
      </b-table>
      <b-card-footer>
        <div class="d-flex justify-content-between">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="data.length"
              :per-page="perPage"
              align="left"
              class="my-0"
            />
          </div>
          <div>
            <b-button @click="createModels" variant="primary">
              <b-spinner small v-if="creatingModels"></b-spinner>
              Create Models <feather-icon icon="PlusIcon"></feather-icon>
            </b-button>
          </div>
        </div>
      </b-card-footer>
    </b-card>

    <b-card
      v-if="issues.length"
      title="Error(s) Found"
      border-variant="danger"
      bg-variant="transparent"
    >
      <b-table responsive="sm" :items="issues">
        <template #cell(name)="data">
          <span v-if="data.item.type === 'Inventory'" class="text-nowrap">
            {{ data.item.brand }} / {{ data.item.model }} / {{ data.item.name }}
          </span>
          <span v-else class="text-nowrap">
            {{ data.item.name }}
          </span>
        </template>
        <template #cell(message)="data">
          <span class="text-nowrap">
            <strong>
              {{ data.item.message }}
            </strong>
          </span>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormCheckbox,
  BButton,
  BAvatar,
  BCardFooter,
  BPagination,
  BBadge,
  BAlert,
  BSpinner,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormCheckbox,
    BButton,
    BAvatar,
    BCardFooter,
    BPagination,
    BBadge,
    BAlert,
    BSpinner,
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      },
    },
    issues: {
      type: Array,
      default: () => {
        return [];
      },
    },
    creatingModels: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        "inventory",
        "brand",
        "model",
        "item_condition",
        "stock",
        "stock_warning_at",
        "unit_price",
      ],
      perPage: 10,
      currentPage: 1,
    };
  },
  methods: {
    createModels() {
      this.$emit("createModels");
    },
  },
};
</script>

<style>
.table-image-model {
  max-width: 50px;
}
</style>
