<template>
  <div>
    <div>
      <b-alert variant="danger" show>
        <div
          class="alert-body d-flex justify-content-between align-items-center"
        >
          <div class="notice-text">
            <div>
              Please download our recommended template file to import your
              <b>Inventories</b>. Do not change the format of the file or the
              extension. Please follow the order of the columns. There should
              not be any repetition in the data.
            </div>
            <br />
            <div>
              IMPORTANT: Do not change the column headings in the template file.
              These need to be unchanged for the import to work in the next
              step.
            </div>
          </div>
          <div>
            <b-button @click="downloadTemplateFile" variant="dark"
              >Download <feather-icon icon="DownloadIcon"></feather-icon>
            </b-button>
          </div>
        </div>
      </b-alert>
    </div>

    <b-card class="mt-2" title="Upload the updated XLS or XLSX file">
      <b-row>
        <b-col cols="6">
          <b-form-file
            v-model="file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-col>
        <b-col cols="6">
          <b-button
            :disabled="uploadingFile"
            @click="uploadFile"
            variant="primary"
          >
            <b-spinner small v-if="uploadingFile"></b-spinner> Upload & Process
            File</b-button
          >
        </b-col>
      </b-row>
    </b-card>

    <bulk-upload-inventory-process-screen
      @createModels="bulkUploadModels"
      :creatingModels="creatingModels"
      :data="data"
      :issues="issues"
      v-if="showModelProcessScreen"
      class="mt-4"
    />

    <b-modal
      @close="handleOk"
      @ok="handleOk"
      v-model="showSuccessModal"
      no-close-on-backdrop
      id="modal-center"
      modal-class="modal-success"
      centered
      title="Success"
      ok-only
      ok-title="OK"
    >
      <b-card-text>
        The database has been successfully populated with the imported data. You
        may now close this window, and you'll find the iventories in the
        inventory management page.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BCard,
  BSpinner,
  BModal,
} from "bootstrap-vue";
import { getUserToken } from "@/auth/utils";
import axios from "axios";

import BulkUploadInventoryProcessScreen from "@/views/InventoryManagement/BulkUploadInventoryProcessScreen.vue";

export default {
  components: {
    BAlert,
    BButton,
    BulkUploadInventoryProcessScreen,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BSpinner,
    BModal,
  },
  data() {
    return {
      file: null,
      uploadingFile: false,
      creatingModels: false,
      data: [],
      issues: [],
      showModelProcessScreen: true,
      showSuccessModal: false,
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    delay(time) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, time);
      });
    },
    uploadFile() {
      if (!this.file) {
        return alert("Select file to continue!");
      }

      this.uploadingFile = true;
      var formData = new FormData();
      formData.append("media", this.file);

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/uploads/inventory`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.uploadingFile = false;
          this.showModelProcessScreen = true;
          this.data = response.data.data;
          this.issues = response.data.issues;
        })
        .catch((error) => {
          this.uploadingFile = false;
          console.log(error.response.data);
        });
    },
    downloadTemplateFile() {
      const downloadUrl = `${process.env.VUE_APP_SERVER_URL}/api/uploads/bulk-upload/download-template/inventory`;
      const anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.target = "_blank";
      anchor.download = "inventory-template-file.xlsx";
      anchor.click();
    },
    bulkUploadModels() {
      this.creatingModels = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/uploads/inventories/create`,
          { data: this.data },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(async (response) => {
          this.creatingModels = false;
          this.showSuccessModal = true;
        })
        .catch((error) => {
          this.creatingModels = false;
          this.makeToast(
            "danger",
            "Error",
            "Coudln't import the data. Please try again later."
          );
          console.log(error.response.data);
        });
    },
    handleOk() {
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.notice-text {
  max-width: 80%;
}

@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
